.App-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(29, 5, 92, 0.674);
  width: 100%;
  height: fit-content;
  position: static;
  z-index: 2;
  bottom: 0;
}

.Footer-text-wrapper {
  width: 85%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.App-footer img {
  max-width: 300px;
  width: 90%;
  padding: 10px;
}

.App-footer p {
  color: white;
  padding: 20px;
  text-align: start;
}

.App-footer a {
  color: #c0f5f4;
}



@media only screen and (max-width: 1000px) {
  .App-footer p {
    font-size: 14px;
    text-align: end;
  }

  .App-footer img {
    width: 40%;
  }  

  .Footer-text-wrapper {
    width: 90%;
    max-width: 700px;
  }
}

@media only screen and (max-width: 450px) {
  .App-footer p {
    font-size: 14px;
    text-align: end;
  }

  .App-footer img {
    width: 50%;
  }

  .Footer-text-wrapper {
    width: 90%;
    max-width: 700px;
  }
}