.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  max-width: 800px;
}

.About-title {
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
}

.About-subheading {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
}

.Content-wrapper-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;
}

.team-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px;
}

.team-heading {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

.team-member {
  padding: 20px;
}

.team-member-photo {
  width: 100%;
  height: auto;
}

.team-member-name {
  margin-top: 10px;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 4px;
}

.faq {
  width: 70%;
  align-items: center;
  border-radius: 4px;
  padding: 30px;
  margin: 20px;
}

.faq-heading {
  text-align: center;
  margin-bottom: 20px;
}

.faq summary {
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
}

.faq details {
  width: 100%;
  text-align: left;
}

.faq details:not(:last-child) {
  margin-bottom: 40px;
}

.faq h2 {
  width: 100%;
  margin-bottom: 32px;
}

details {
  transition: all 0.3s ease-in-out;
}

details[open] summary {
  font-weight: bold;
}

details p {
  margin: 0;
  padding: 0 0 10px 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

details[open] p {
  animation: fadeIn 0.3s ease-in-out forwards;
  max-height: 200px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

summary {
  cursor: pointer;
  list-style: none; /* Removes default triangle icon */
  position: relative;
}

summary::after {
  content: "▼";
  font-size: 12px;
  margin-left: 8px;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

details[open] summary::after {
  transform: rotate(180deg);
}

@media screen and (max-width: 1000px) {
  .About-container {
    max-width: 500px;
  }

  .faq {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .About-container {
    max-width: 350px;
  }

  .faq {
    width: 100%;
  }
}
