@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rasa:ital,wght@0,300..700;1,300..700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #235992;
  --secondary-color: #805ad5;
  --light-bg: #f8fafc;
  --text-dark: rgb(25, 11, 68);
  --border-color: #815ad572;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  width: 100%;
}

.Top-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  border-bottom: 1px solid #815ad572;
  margin-bottom: 80px;
}

h1 {
  font-size: 44px;
  font-weight: 900;
  margin-top: 50px;
  text-align: left;
  font-family: "Rasa", serif;
}

h2 {
  font-size: 30px;
  font-weight: 900;
  font-family: "Rasa", serif;
}

.App-header {
  background-color: #ffffff;
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #815ad572;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 75px;
  overflow-y: auto;
  height: calc(100vh - 75px);
}

.Top-ledger {
  width: 85%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.Right-ledger {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Top-ledger-logo {
  height: 40px;
}

.Top-ledger-link {
  text-decoration: none;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  align-self: center;
  color: #235992;
  font-weight: 700;
}

.About-link {
  font-weight: 700;
  margin-bottom: 40px;
}

.About-link a {
  text-align: center;
  text-decoration: none;
  color: #235992;
}

.Top-ledger-link:hover {
  color: #8c65b2;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  position: absolute;
  right: 100px;
  top: 150px;
  z-index: 1;
}

.Content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 75px;
  max-width: 800px;
}

.Content-wrapper-top {
  margin-top: 60px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 50px;
  max-width: 1000px;
  flex-direction: column;
  text-align: left;
  align-items: start;
}

.Content-wrapper-top p {
  text-align: left;
  font-size: 18px;
  margin-bottom: 60px;
  margin-top: 20px;
  color: rgb(25, 11, 68);
  max-width: 750px;
  text-wrap: balance;
}

.Button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: 150px;
}

.Purple-blob {
  position: absolute;
  top: 100px;
  right: 100px;
  height: 85%;
  z-index: -1;
  object-fit: cover;
  color: #e9d8fd;
  animation: float 20s ease-in-out infinite;
}

@keyframes float {
  0% {
    right: 100px;
  }
  50% {
    right: 200px;
  }
  100% {
    right: 100px;
  }
}

button.App-button {
  background-color: #235992;
  color: #f8fafc;
  font-weight: 700;
  border: none;
  padding: 12px 20px;
  min-width: 100px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  border-radius: 5px;
}

button.App-button:hover {
  background-color: #805ad5;
  color: #f8fafc;
}

button.App-button-white {
  margin-top: 8px;
  background-color: #f8fafc;
  color: #805ad5;
  border: 1.5px solid #805ad5;
}

a {
  text-decoration: none;
  color: #4a2392;
  font-weight: 700;
}

.Content-wrapper-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  width: fit-content;
  max-width: 800px;
  padding: 20px;
}

.Content-wrapper-cell p {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0px;
}

.Content-wrapper-bottom {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}

.Onboarding-steps-container p {
  margin-bottom: 20px;
  margin-top: 0px;
  text-wrap: balance;
}

.Scroll-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: move-up-down 1s ease-in-out infinite alternate;
}

@keyframes move-up-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}

.Onboarding-steps-container {
  display: flex;
  scroll-snap-type: y mandatory;
  padding-top: 50px;
  justify-content: center;
  overflow-y: scroll;
  scrollbar-width: none;
}

.Description-box {
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: "start";
}

.Description-box video {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .Top-ledger-logo {
    height: 2.5rem;
  }

  .Top-ledger {
    width: 90%;
    max-width: 700px;
  }

  .Content-wrapper-top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 700px;
  }

  .Content-wrapper-top p {
    font-size: 16px;
    text-wrap: balance;
    line-height: 1.5rem;
    margin-bottom: 20px;
  }

  .Description-box p {
    font-size: 17px;
    line-height: 1.5rem;
  }

  .Content-wrapper-bottom {
    width: 95%;
    max-width: 700px;
  }

  .Content-wrapper-cell p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0px;
    font-weight: 400;
    font-style: italic;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 26px;
    text-wrap: balance;
    margin-bottom: 20px;
  }

  body {
    font-size: 16px;
    max-width: 100%;
  }

  button.App-button {
    padding: 12px 20px;
    font-size: 16px;
  }

  .Scroll-indicator {
    margin-top: 0px;
  }
}
